function Contacto() {
    return (
      <>
    <div className="contact">
    <div class="container">

<div class="cover">
  <h1 id="contacto">Pongámonos en contacto</h1>
  <p>Pronto recibirás una respuesta.</p>
  <form  class="flex-form" action="https://formsubmit.co/asexorateconale@gmail.com" method="post">
    <label for="from">
    </label>
    <input type="text" placeholder="Nombre" id="full_name" name="Nombre" required/><br></br>
    <input type="email" placeholder="Correo" id="email" name="Correo" required/><br></br>
    <input type="number" placeholder="Teléfono sin el 0 y el 15, Ej: 2613413381" id="tel" name="Teléfono" pattern="[0-9]{10}" required/><br></br>
    <textarea placeholder="Tu consulta" id="message" name="Consulta" required></textarea><br></br>
    <div className="enviar">
    <input type="submit" value="Enviar"/>
    </div>
    <input type="hidden" name="_next" value="https://asexorate.com.ar/Enviado"/>
    <input type="hidden" name="_subject" value="Nueva consulta desde la web Asexorate!"/>
    <input type="hidden" name="_captcha" value="false" />
  </form>
</div>

</div>
    </div>
      </>
    );
  }
  
  export default Contacto;