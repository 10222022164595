import IG1 from "../assets/21.png";
import IG5 from "../assets/26.png";
import IG2 from "../assets/22.png";
import IG3 from "../assets/24.png";
import IG4 from "../assets/27.png";
import IG6 from "../assets/25.png";
import IG7 from "../assets/23.png";

function InstagramFeed() {

  return (
    <div className='container-feed'>
      <div className="titulosIG">
      <h2 data-aos="fade-down" data-aos-easing="ease-in-sine" data-aos-duration="1000">Seguinos en <span className="home-hero-underline">Instagram</span></h2>
      <p><a href='https://www.instagram.com/alexandra_gebarski/' target='_blank' rel="noopener noreferrer">@asexorate</a></p>
      </div>
      <div className="instagram-feed">
        <a href='https://www.instagram.com/alexandra_gebarski/' target='_blank' rel="noopener noreferrer">
        <img src={IG1} alt="Imagen de instagram 1"/>
        </a>
        <a href="https://www.instagram.com/alexandra_gebarski/" target="_blank" rel="noopener noreferrer">
        <img src={IG2} alt="Imagen de instagram 2"/>
        </a>
        <a href="https://www.instagram.com/alexandra_gebarski/" target="_blank" rel="noopener noreferrer">
        <img src={IG3} alt="Imagen de instagram 3"/>
        </a>
        <a href="https://www.instagram.com/alexandra_gebarski/" target="_blank" rel="noopener noreferrer">
        <img src={IG4} alt="Imagen de instagram 4"/>
        </a>
        <a href="https://www.instagram.com/alexandra_gebarski/" target="_blank" rel="noopener noreferrer">
        <img src={IG5} alt="Imagen de instagram 5"/>
        </a>
        <a href="https://www.instagram.com/alexandra_gebarski/" target="_blank" rel="noopener noreferrer">
        <img src={IG6} alt="Imagen de instagram 5"/>
        </a>
        <a href="https://www.instagram.com/alexandra_gebarski/" target="_blank" rel="noopener noreferrer">
        <img src={IG7} alt="Imagen de instagram 5"/>
        </a>
      </div>
    </div>
  );
};

export default InstagramFeed;
