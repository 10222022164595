import Reproductiva from "../assets/reproductiva.png";
import Embarazo from "../assets/embarazo.png";
import Fem from "../assets/cuidados.png";

function Servicios() {
    return (
        <>
        <div className="serviceGlobal">

  <h2 className="titleServicios" data-aos="fade-down" data-aos-easing="ease-in-sine" data-aos-duration="1000" id="servicios">Explora nuestros <span className="home-hero-underline">servicios</span></h2>  
    <div className="servicios" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000">
  <div className="graph1">
    <img src={Reproductiva} alt="aparato reproductor femenino" width="200"/>
    <h3>Salud <span className="home-hero-underline">reproductiva</span></h3>
    <p>Ofrecemos servicios de ginecología centrados en tu salud reproductiva. Nuestra misión es asegurarnos de que te informes y te cuides en cada etapa de tu vida.</p>
  </div>
  <div className="graph2">
    <img src={Embarazo} alt="mujer embarazada" width="200"/>
    <h3><span className="home-hero-underline">Maternidad</span></h3>
    <p>Con apoyo experto, estamos aquí para acompañarte en cada paso, desde la planificación hasta el posparto, brindándote la atención que necesitas.</p>
  </div>
  <div className="graph3">
    <img src={Fem} alt="Símbolo femenino" width="200"/>
    <h3>Cuidado <span className="home-hero-underline">femenino</span></h3>
    <p>Nos especializamos en la atención integral de la mujer, abordando tus necesidades y ofreciendo  soluciones adaptadas a cada caso. Tu bienestar es nuestra prioridad.</p>
  </div>
    </div>
        </div>
    </>
    );
  }
  
  export default Servicios;