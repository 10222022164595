import Logo from "../assets/Logo Alexandra Gibarski.png";
import React, { useState } from 'react';

function Navbar() {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };
    return (
      <>
      
<div className={`menu ${menuActive ? "active" : ""}`}>
  <img src={Logo} alt="Logo Alexandra Gebarski" width="150" />
  <div class="mobile-menu" onClick={toggleMenu}>
    <div class="hamburger">
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
    </div>
  </div>
  <ul>
    <li><a href="/">INICIO</a></li>
    <li><a href="#nosotros">NOSOTROS</a></li>
    <li><a href="#contacto">CONTACTO</a></li>
  </ul>
</div>
  <hr></hr>
  </>

    );
  }
  
  export default Navbar;