import Women from "../assets/womens.jpg";

function Header() {
    return (
    <div className="header">
        <div className="textCabecera">
        <h1>Ginecología y <br/>salud reproductiva, <br/> para la mujer <span className="home-hero-underline">moderna.</span></h1>
        <p>Comprometida con la salud sexual, maternidad y el cuidado de la mujer. Delicadeza, excelencia y profesionalismo.</p>
        <div className="containerBtn">
        <button className="btn"><a href="#nosotros">SABER MÁS</a></button>
        </div>
        </div>
        <div>
        <img src={Women} alt="Mujeres felices"/>
        </div>
  
    </div>
    );
  }
  
  export default Header;