import Alexandra from "../assets/Alexandra.png";

function Nosotros() {
    return (
      <>
      <div className="nosotrosGlobal">
      <h2 className="titleNosotros" data-aos="fade-down" data-aos-easing="ease-in-sine" data-aos-duration="1000" id="nosotros">Sobre mí</h2> 
    <div className="nosotros">
  <img src={Alexandra} alt="Alexandra Gebarski" width="500" data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-duration="1000" className="alexandra"/>
  <p className="textNosotros" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="1000">Hola, soy <span className="home-hero-underline">Alexandra</span>, mendocina recibida de la Universidad Aconcagua. <br></br><br></br>Realicé mi especialidad en <span className="home-hero-underline">ginecología</span> y <span className="home-hero-underline">obstetricia</span> en el Hospital Diego Paroissien, donde el día de hoy me desempeño como jefa de guardia del servicio de ginecología.  <br></br><br></br>Cuento con un <span className="home-hero-underline">posgrado</span> en medicina legal, otorgado por la Universidad Nacional de Cuyo. <br></br><br></br>Con un consultorio privado creamos un ambiente confortable y seguro para tu consulta, donde contamos con un servicio<br></br> <span className="home-hero-underline">integral</span> y personalizado para cada paciente que nos elige.</p>
</div>
      </div>
</>
    );
  }
  
  export default Nosotros;