import Gmail from "../assets/email-black.png";
import Instagram from "../assets/Instagram-green.png";
import Maps from "../assets/maps.png"

function Footer() {
    return (
      <div className="footer">
      <div className="infoContact">
        <div className="textFooter">
        <p>Nuestro correo electrónico para consultas: <span><a href="mailto:asexorateconale@gmail.com">asexorateconale@gmail.com</a></span>. <br/><br/>Podés ubicarnos en <span><a href="https://maps.app.goo.gl/obo9NHofRU6D4KfBA" target="_blank" rel="noopener noreferrer">Juan B. Justo 427</a></span> de Godoy Cruz, CP5504 Mendoza.<br/><br/> Horarios: Lunes de <span className="anchor">16 a 19 hs</span> y Miércoles de <span className="anchor">9 a 11:30 hs</span>. <br/><br/>Para <span className="anchor">consultas virtuales</span>, coordinar horario.<br></br><br/>Tel: <span><a href="tel:2613413381">2613413381</a></span></p>
        </div>
        <div>
        <a href="mailto:asexorateconale@gmail.com" target="_blank" rel="noopener noreferrer"><img src={Gmail} alt="Logo de correo electrónico" width="50" className="icons"/></a>
        <a href="https://www.instagram.com/alexandra_gebarski/" target="_blank" rel="noopener noreferrer"><img src={Instagram} alt="Logo de Instagram" width="52" className="icons"/></a>
        <a href="https://maps.app.goo.gl/obo9NHofRU6D4KfBA" target="_blank" rel="noopener noreferrer"><img src={Maps} alt="Logo de WhatsApp" width="49" className="icons"/></a>
        </div>
      </div>
  <footer>
          Copyright ©{" "}
          {new Date().getFullYear()} <span>[ <a href="https://ekoddex.com/" target="_blank" rel="noopener noreferrer">eKoddex</a> ]</span> Diseño y Programación | Mendoza - Argentina{" "}    
  </footer>
  <br></br>
    </div>
    );
  }
  
  export default Footer;
